body {
  background-color: #F0F0F0;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  border-bottom: 5px solid gray;
  max-width: 100%;
}

header h1 {
  padding-left: 3em;
}

header button {
  width: 10%;
  height: 50%;
  margin-right: 5em;
}

main {
  background-color: #F0F0F0;
  max-width: 100%;
  min-height: 100px;
  overflow: hidden;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  height: 5em;
  width: 100% ;
  padding-top: 2em;
}

.filterContainer div {
  width: 30%;
  padding-left: 6em;
}

.searchBar {
  height: 4em;
  min-width: 100%;
  border-radius: 4px;
  border: none;
  font-size: 1em;
}

.region-select {
  height: 4em;
  min-width: 50%;
  border: none;
}

.all-countries-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 100px;
  max-width: 100%;
  overflow: hidden;
  margin: 3em;
}

.all-countries-card {
  width: 100%;
  height: 25em;
  background-color: white;
  border-radius: 5px;
}

.all-countries-flag {
  width: 100%;
  height: 10em;
  outline: .5px solid #F0F0F0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.all-countries-info {
  padding-left: 2em;
  padding-right: 1em;
}

h2 {
  width: 100%;
}

h3 {
  margin: 0 0 .25em 0;
}

h3 > span {
  font-weight: lighter;
}

a {
  max-width: 17.75em;
  min-width: 17.75em;
  margin: 2em 0 2em 3em;
  text-decoration: none;
  color: black;
}


.country-info-wrapper {
  display: flex;
}

.country-info {
  width: 50%;
}

.country-flag-wrapper{
  width: 50%;
  padding-left: 4em;
}

@media(max-width: 1250px) {
  .country-info-wrapper {
    display: block;
  }

  .country-info {
    padding-left: 5em;
    width: auto;
  }

  .country-info-wrapper .country-flag-wrapper{
    width: auto;
    max-width: 85%;
  }

  div .country-main-info {
    display: block;
    height: auto;
  }

  div .country-main-info h3 {
    width: auto;
  }
}

.country-flag {
  min-height: 20em;
  max-width: 75%;
}

@media(max-height: 700px) {
  .country-flag {
    max-width: 90%;
  }
}

.country-main-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 10em;
  max-width: 100%;
}

.country-name {
  font-size: 2em;
}

.back-button-wrapper {
  padding: 4em 0 4em 4em;
}

.back-button {
  height: 3em;
  width: 8em;
  text-align: center;
}

button.back-button a, .border a{
  margin: 0;
}

.wrapper {
  background-color: #F0F0F0;
  min-height: 100px;
}

.country-main-info h3 {
  width: 50%; 
}

.border {
  margin: 0 .5em;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--placeholder-color);
}

.weather-info-wrapper {
  min-height: 100px;
}

.weather-info {
  max-width: 22.5%;
  min-height: 15em;
  background-color: gray;
  margin: 5em auto;
  border-radius: 5px;
  padding: 0 2em;
}

/* When width gets below 1200px change weather info */
/* when width gets below 1100px change country info wrapper */
@media (max-width: 1200px) {
    .weather-info {
      min-width: 30%;
    }
}

@media (max-width: 650px) {
  .weather-info {
    min-width: 55%;
  }
}

@media (width > 650px) and (width <= 900px) {
  .weather-info {
    max-width: 40%;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.weather {
  font-size: 2em;
}

.weather-image {
  max-width: 100%;
  height: auto;
}

.temp-div {
  width: auto;
}

.temperature {
  font-size: 4em;
  letter-spacing: -5px;
}

/* .weather-details {
  width: 70%;
  padding-left: 2em;
} */

.bottom {
  display: flex;
  justify-content: space-between;
}

.forecast-info {
  display: flex;
  justify-content: space-between;
  height: 4em;
  background-color: white;
  margin: 0.5em 3em 0.5em 3em;
  border-radius: 5px;
}

.forecast-pic {
  height: 2em;
}

.forecast-weather {
  display: flex;
  align-items: center;
  padding-left: 1em;
}

.forecast-weather p{
  padding-left: 1em;
}

.forecast-details {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.forecast-details span {
  padding-right: 1em;
}

.daily-grid {
  grid-row-gap: 0;
  grid-column-gap: 15px;
  column-gap: 15px;
  display: grid;
  flex: 1 1;
  grid-template-columns: auto auto;
  padding: 1em 4em;
}

.daily-items {
  height: 2em;
}

.no-weather {
  font-size: 3em;
  text-align: center;
  margin: 2em auto;
}